/* @import url('./index.css'); */

/* .testimony-container {
   display: flex;
   justify-content: space-around;
   flex-direction: row;
   text-align: center;
   gap: 20px;
} */


.testimony-wrapper {
   display: flex;
   align-items: center;
   flex-direction: column;
   max-width: 300px;
   box-shadow: 0 10px 30px -15px var(--navy-shadow);
   display: flex;
   -webkit-box-pack: justify;
   justify-content: space-between;
   -webkit-box-align: center;
   flex-direction: column;
   position: relative;
   height: 100%;
   padding: 1rem;
   border-radius: var(--border-radius);
   background-color: var(--light-navy);
   transition: var(--transition);
}


.img-profile {
   height: 60px;
   width: 60px;
   object-fit: contain;
   transform: translateY(-100%);
   border-radius: 50%;
   outline: 2px solid white;
   outline-offset: 2px;
   position:fixed;
}
.user-name,
.user-profession,
.testimony-p {
   padding: 10px;
}

.user-name{
   padding-top: 20px;
   padding-bottom: unset;
}

.user-profession {
   padding-top: unset;
   font-size:small;
}

p.testimony-p {
   line-height: 22px;
   font-size: 15px;
}


/* Carousel */
.testimony-container {
    position: relative;
    width: clamp(250px, 40vw, 470px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow-x: clip;
    margin: 0 auto;
}
.carousel {
  display: flex;
  transition: transform 0.5s var(--easing);
}

.testimony-wrapper {
   min-width: 100%;
   box-sizing: border-box;
   max-width: 300px;
   padding-block: 2rem;
   padding-inline: clamp(1rem, 3vw, 1.5rem);
   margin: 0 auto;
   text-align: center;
   background-color: var(--light-navy);
   border-radius: var(--border-radius);
   box-shadow: 0 10px 30px -15px var(--navy-shadow);
}
.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.carousel-control.prev {
  left: 0;
}

.carousel-control.next {
  right: 0;
}

.qoutes_c > img {
   width:15px;
   opacity: 25%;
}

.qoutes_c img:nth-child(1){
   transform: scaleX(-1);
}

.qoutes_c {
    width: 161%;
    position: fixed;
    display: flex;
    top: 110px;
    justify-content: space-around;
}