
/* @import url('./index.css'); */
.contact-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* padding: 50px; */
}

.contact-text {
  width: 50%;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  gap: clamp(20px, 15vw, 70px);
  top:-60px;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
}

.message_gif {
  -webkit-user-drag:none;
      width: 200px;
}

.contact-form {
  width: 50%;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  color: var(--on-tertiary);
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 15px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  color: var(--on-tertiary);
  background: transparent;
  border: 1px solid var(--on-tertiary-45)
}

.form-group textarea {
  resize: vertical;
  min-height: 150px;
  background-color: var(--on-primary);
}

.form-group textarea::-webkit-scrollbar {
    width: 8px;
}
.form-group textarea::-webkit-scrollbar-track {
    background: var(--bg-scroll);
}
.form-group textarea::-webkit-scrollbar-thumb {
    background: var(--on-tertiary);
    border-radius: 10px; 
}
.form-group textarea::-webkit-scrollbar-thumb:hover {
    background: darken(var(--on-tertiary), 10%);
}

.submit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 200px;
    border: 2px solid var(--on-primary);
    margin: 50px 0 0 0;
    padding: 18px 25px;
    background-color: var(--on-tertiary);
    color: var(--on-primary);
    cursor: pointer;
    border-radius: 5px;
    /* font-family: var(--font-mono); */
}

.submit-button:hover {
  transition: var(--transition);
  box-shadow: 4px 4px 0 0 var(--on-tertiary);
  transform: translate(-5px, -5px);
  background-color: va;
  color:var(--on-primary);
  font-weight: 600;
  background-color: var(--on-tertiary) ;
}

.submitBtn {
  display: flex;
  justify-content: flex-end;
}

a.getInTouch{
  text-decoration: none;
}


.employed-contact-me {
  display: flex;
  max-width: 600px;
  margin: 0px auto 100px;
  text-align: center;
  flex-direction: column;
  align-items: center;
}


@media (max-width: 768px) {

  .message_gif{
    display: none;
  }
  .contact-text {
    padding: unset;
    position: relative;
    top: unset;
    width: 100%;
    text-align: center;
  }

  .contact-container {
      display: flex;
      flex-direction: column;
  }
  .contact-form {
    margin-top: 20px;
    width: 80%;
    max-width: 300px;
  }

  .form-group input, .form-group textarea {
    width: 100%;
    padding: 5px;
    font-size: 12px;
    border-radius: 4px;
    color: var(--on-tertiary);
    background: transparent;
    border: 1px solid var(--on-tertiary-45);
  }

  .submitBtn button{
    margin-top: 20px;
    width: 100%;
  }


}