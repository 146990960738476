/* ===================== Projects =================== */
.featured-projects {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.projects:not(:last-of-type) {
    margin-bottom: 20px;
}

/* .windowPreview {
    overflow-y: hidden;
} */

/* gvznfB */
.projects:nth-of-type(2n+1) .project-content {
    grid-column: 7 / -1;
    text-align: right;
}
.projects:nth-of-type(2n+1) .project-image {
    grid-column: 1 / 8;
}
.projects:nth-of-type(2n+1) .project-tech-list {
    -webkit-box-pack: end;
    justify-content: flex-end;
}

.projects:nth-of-type(2n+1) .project-links {
    -webkit-box-pack: end;
    justify-content: flex-end;
    margin-left: 0px;
    margin-right: -10px;
}


.projects {
   position: relative;
   display: grid;
   gap: 10px;
   grid-template-columns: repeat(12, 1fr);
   -webkit-box-align: center;
   align-items: center;
}

.project-overline {
    margin: 10px 0px !important;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: var(--fz-xs);
    font-weight: 400;
}

.project-content {
   position: relative;
   grid-area: 1 / 1 / -1 / 7;
   bottom:130px
}

.project-content.project2Content{
    bottom: 200px
}

.project-title {
   color: var(--lightest-slate);
   font-size: clamp(24px, 5vw, 28px);
}

.project-description {
   box-shadow: 0 10px 30px -15px var(--navy-shadow);
   transition: var(--transition);
   position: relative;
   z-index: 2;
   padding: 25px;
   border-radius: var(--border-radius);
   background-color: var(--light-navy);
   color: var(--light-slate);
   font-size: var(--fz-lg);
}

.project-tech-list {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
    margin: 25px 0px 10px;
    padding: 0px;
    list-style: none;
}

.project-tech-list li {
    margin: 0px 20px 5px 0px;
    color: var(--light-slate);
    font-family: var(--font-mono);
    font-size: var(--fz-xs);
    white-space: nowrap;
}

.project-links {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    margin-top: 10px;
    margin-left: -10px;
    color: var(--lightest-slate);
}

.project-links a {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    padding: 10px;
}


.project-image {
    /* box-shadow: 0 10px 30px -15px var(--navy-shadow); */
    transition: var(--transition);
    grid-area: 1 / 6 / -1 / -1;
    position: relative;
    z-index: 1;
}

.imgPreview ,
.stt1{
   height: 100%;
   border-radius:10px
}

.skycast2,
.skycast3,
.stt2,
.stt3,
.schedBin1,
.schedBin2,
.schedBin3,
.schedBin4 {
   width: 150px;
   height: 300px;
   transition: var(--transition);
}

/* .skycast2 {
    transform: translate(0, 50px);
} */

.skycast3, .stt3 {
    transform: translate(0, -50px);
}

.stt3 {
    transform: translate(0, 70px);
}

.mobilePreview {
   display: flex;
   justify-content: center;
   gap:30px;
}

.mobilePreview.sttMobilePreview {
    gap:70px;
    margin-bottom: 120px;
}

.mobilePreview.schedBinMobilePreview {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.project-content.project3Content {
    bottom: 260px;
}

.project-content.project4Content {
    margin-top: 150px;
} 

.schedBin1 {
    transform: translate(0, -80px);
}

.schedBin2 {
    transform: translate(0, 40px);
}

.schedBin4 {
    transform: translate(0, 80px);
}

.imgAbove, .imgBelow {
    display: flex;
    gap: 50px;
} 

.imgBelow {
    transform: translate(-50px, -10px);
}

/* img.imgPreview.stt1 {
    transform: translate(0px, -55px) !important;
} */

img.trackRecord{
    top: 0;
    border: 1px solid var(--tertiary-10);
    position: relative;
    transform: translate(0, -140px);
    border-radius: 10px;
}

.content-wrapper {
    padding-inline: 25px;
    padding-block: 15px;
}


@media (max-width: 1080px) {
    .projects:nth-of-type(2n+1) .project-content {
        grid-column: 5 / -1;
        text-align: left; 
    }
    .projects:nth-of-type(2n+1) .project-tech-list {
        justify-content: unset;
    }
    .projects:nth-of-type(2n+1) .project-links {
        justify-content: flex-start;
        margin-left: 0px;
    }
    .project-description {
        padding: 25px 0 25px 0;
        background-color: unset;
        color: var(--slate);
        box-shadow: unset;
    }

    /* first project  */
    li.projects.proj1>.project-content {
        background-image: url('./../imgs/skycast/skycast1.png');
        background-size: cover ;
        border-radius: 10px;
    }
     li.projects.proj1>.project-content>.content-wrapper {
        background-color: var(--on-primary2);
        border-left: 1px solid var(--on-tertiary2);
        border-bottom: 1px solid var(--on-tertiary2);
        border-radius: 10px;
    }

    /* second project  */
    li.projects.proj2>.project-content {
        background-image: url('./../imgs/stt/stt1.png');
        background-size: contain ;
        border-radius: 10px;
    }
    li.projects.proj2>.project-content>.content-wrapper {
        background-color: hsl(230deg 61% 14% / 93%);
        border-radius: 10px;
    }
    
    /* third project */
    li.projects.proj3 > .project-content {
        position: relative;
        border-radius: 10px;
        /* padding: 20px;  */
    }

    li.projects.proj3 > .project-content::before,
    li.projects.proj3 > .project-content::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0vw;
        width: 32%;
        height: 49%;
        background-size: contain;
        background-repeat: no-repeat;
        transform: skewX(-15deg); 

    }

    li.projects.proj3 > .project-content::before {
        z-index: -1;
        transform: translateX(-50%) skewX(-15deg);
        background-image: url('./../imgs/schedBin/SchedBin1.png');
        /* width: 30%; */
    }

    li.projects.proj3 > .project-content::after {
        background-image: url('./../imgs/schedBin/SchedBin3.png');
        z-index: -1;
        right: 2.5vw;
        /* width: 30%; */
        top: 25vh;
    }
    
    li.projects.proj3>.project-content>.content-wrapper {
        background-color: var(--on-primary2);
        border-radius: 10px;
        border-left: 1px solid var(--on-tertiary2);
        border-bottom: 1px solid var(--on-tertiary2);
    }

    /* forth project */
    li.projects.proj4>.project-content {
        background-image: url('./../imgs/Portfolio.png');
        border-radius: 10px;
        background-position: center;
    }
    li.projects.proj4>.project-content>.content-wrapper {
        background-color: hsl(230deg 61% 14% / 75%);
        border-radius: 10px;
        border: 1px solid var(--on-tertiary2)
    }
}

@media (min-width: 768px) {
    li.projects.proj1>.project-content {
        background-image: unset;
    }
    .project-title {
        margin: 0px 0px 20px;
    }
    .project-content.project4Content {
        margin-top: unset;
    } 
}

@media only screen and (min-width: 768px) and (max-width: 768px) {
    li.projects.proj1>.project-content {
        background-image: url('./../imgs/skycast/skycast1.png');
        background-size: cover;
        border-radius: 10px;
    }
}


@media only screen and (min-width: 769px) and (max-width: 1079px) {
    .projects.proj1 .project-content {
        top:0;
        z-index: 2;
    }

    li.projects.proj1>.project-content>.content-wrapper,
    li.projects.proj2>.project-content>.content-wrapper {
        background-color: var(--on-primary);
    }

    li.projects.proj2>.project-content,
    li.projects.proj3 > .project-content,
    .project-content.project4Content > .content-wrapper {
        background-image: unset;
        background-color: var(--on-primary);
        z-index: 2;
        position: relative;
    }

    li.projects.proj2>.project-content>.content-wrapper {
        top: 20px;
        position: relative;
        border: 1px solid var(--on-tertiary2);
    }

    li.projects.proj3 > .project-content::before,
    li.projects.proj3 > .project-content::after,
    li.projects.proj4>.project-content {
        background-image: unset;
    }
    img.imgPreview.schedBin2 {
        display: none;
    }

    img.imgPreview.skycast3 {
        visibility: hidden;
    }
}

@media only screen and (max-width: 450px) {

    li.projects.proj3 > .project-content::after,
    li.projects.proj3 > .project-content::before {
        z-index: -1;
        right: 8.5vw;
        width: 25%;
    }
    li.projects.proj3 > .project-content::after {
        top:clamp(25vh, 69%, 51vh);
    }
    li.projects.proj3 > .project-content::before{
        right: 14.5vw;
    }
}